import { Grid, Typography } from '@material-ui/core';
import { PageTitle } from 'app/components/PageTitle';
import { PageComponent } from 'utils/ComponentUtils';
import { observer } from 'mobx-react';
import Helmet from 'react-helmet';

class ErrorPage extends PageComponent {
  render() {
    const {
      appStore: { error },
    } = this.props;

    return (
      <>
        <Helmet>
          <title>Online Service - Tillfälligt fel</title>
        </Helmet>
        <PageTitle>Tillfälligt fel</PageTitle>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Typography variant="h6">Något gick fel</Typography>
            <Typography>Ring Butiksservice för mer information</Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="h6">Teknisk information</Typography>
            {error && <Typography>{JSON.stringify(error)}</Typography>}
            {!error && <Typography>Saknas</Typography>}
          </Grid>
        </Grid>
      </>
    );
  }
}

export default observer(ErrorPage);
