import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Check from '@material-ui/icons/Check';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
  },
}));

const FinancingConditions = () => {
  const classes = useStyles();

  const check = (
    <ListItemAvatar>
      <Avatar className={classes.avatar}>
        <Check />
      </Avatar>
    </ListItemAvatar>
  );

  const Condition = (props: { text: string }) => (
    <ListItem>
      {check}
      <ListItemText>{props.text}</ListItemText>
    </ListItem>
  );

  return (
    <>
      <Typography variant="h6">Grundkrav för att ansöka</Typography>
      <List>
        <Condition text="Fyllt 18 år" />
        <Condition text="Inga betalningsanmärkningar" />
        <Condition text="Årsinkomst minst 150 000kr" />
      </List>
    </>
  );
};

export default FinancingConditions;
