import { Button, Container, Grid, Typography, ListItem } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppStore } from 'app/AppStore';
import { getFinancingStore } from 'app/features/financing/FinancingStore';
import { observable, makeObservable, action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { idleTimer } from '../../utils/Utils';
import { Alert } from 'app/domain/Alert';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { ReactComponent as LogoSvg } from '../resources/avida-logo.svg';
import * as React from 'react';
import AlertDialog from 'app/AlertDialog';
import { CURRENT_TENANT } from 'app/Tenant';

const MOCK_USER: Oidc.User = {
  access_token: 'MOCK_TOKEN',
  id_token: 'MOCK_TOKEN',
  profile: {
    id: 0,
    userId: 0,
    email: 'john.doe@mail.com',
    firstName: 'John',
    lastName: 'Doe',
    sellerId: '133737',
    userName: '1337John',
    amr: [],
    chains: [
      { id: '60', name: 'KIA' },
      { id: '10', name: 'MediaMarkt' },
    ],
    stores: [
      {
        id: '0980979',
        name: 'MediaMarkt Nacka',
        isAdmin: true,
        paymentMethod: 'Ocr',
      },
    ],
    paymentMethod: 'Ocr',
    activeStoreId: '0980979',
    idp: '',
    sid: '',
    sub: '',
  },
  session_state: null,
  refresh_token: '',
  token_type: '',
  expires_at: 0,
  expires_in: 0,
  scope: '',
  expired: false,
  toStorageString: () => '',
  state: {},
  scopes: [],
};

const settings = {
  enabled: JSON.parse(import.meta.env.VITE_SHOULD_AUTH || 'true'),
  authority: import.meta.env.VITE_OIDC_AUTHORITY,
  client_id: import.meta.env.VITE_OIDC_CLIENT,
  redirect_uri: import.meta.env.VITE_OIDC_URL,
  silent_redirect_uri: import.meta.env.VITE_OIDC_URL + '/silent-renew',
  response_type: 'id_token token',
  scope: 'openid api.financingservice.write api.financingservice.read',
  post_logout_redirect_uri: import.meta.env.VITE_OIDC_URL,
  accessTokenExpiringNotificationTime: 60,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  loadUserInfo: true,
  mergeClaims: false,
};

export const userManager = new UserManager(settings);

interface IProps {
  appStore: AppStore;
  children: React.ReactNode;
}

export const Authenticator = observer(
  class Authenticator extends React.Component<IProps> {
    financingStore = getFinancingStore().withAppStore(this.props.appStore);
    hasAuthenticated = false;
    isAuthorized = false;
    user?: Oidc.User;
    settings = settings;
    userManager = userManager;

    constructor(props: IProps) {
      super(props);

      makeObservable(this, {
        hasAuthenticated: observable,
        isAuthorized: observable,
        user: observable,
        userAuthenticated: action,
      });
    }

    async componentDidMount() {
      if (!this.settings.enabled) {
        runInAction(() => {
          this.props.appStore.handleUser(MOCK_USER);
          this.isAuthorized = true;
          this.hasAuthenticated = true;
        });
        return;
      }

      const matchUri = window.location.origin + window.location.pathname;
      let user = null;

      if (window.location.pathname.startsWith('/reset-password')) {
        const pathParts = window.location.pathname.split('/');
        const resetUrl =
          settings.authority +
          '/connect/financing/ResetPassword/' +
          pathParts[pathParts.length - 1] +
          '?returnUrl=' +
          window.location.origin +
          '&tenant=' +
          CURRENT_TENANT;
        window.location.href = resetUrl;
        return;
      }

      // check if we came back from some login screen
      if (window.location.hash.startsWith('#id_token')) {
        // check if it was a silent login
        if (matchUri === this.settings.silent_redirect_uri) {
          await this.userManager.signinSilentCallback();
          return;
        } else {
          try {
            user = await this.userManager.signinRedirectCallback();
            this.props.appStore.router.replace('/');
          } catch (e) {
            // this cam happend when a user enters the redirectUri manually in the browser
            await this.signinRedirect();
          }
        }
      } else {
        // check if previous user session exists.
        // user will be undefined if no session esists.
        user = await this.userManager.getUser();
      }

      if (user && !user.expired) {
        this.userAuthenticated(user);
        this.setupSilentRenew();
      } else {
        // no user session was found so a login is attempted

        // we do not want to redirect to the sign in if IE
        if (this.IEdetection()) {
          return;
        }
        await this.signinRedirect();
      }
    }

    async signinRedirect() {
      const acr_tenant = CURRENT_TENANT;

      const req = await this.userManager.createSigninRequest({
        data: window.location.pathname + window.location.search,
        acr_values: 'tenant:' + acr_tenant,
      });

      window.location.href = req.url;
    }

    setupSilentRenew() {
      this.userManager.events.addAccessTokenExpiring(async () => {
        try {
          const user = await this.userManager.signinSilent();
          this.userAuthenticated(user);
        } catch (e) {
          // signinSilent starts a signing session in a hidden iframe
          // if the iframe does not respond in 10 sec an exception is thrown
          console.error('Caught exception when performing silent signin.', e);
          console.error('Manually redirecting to signin page.');
          await this.userManager.signinRedirect();
        }
      });
    }

    userAuthenticated(user: Oidc.User) {
      this.hasAuthenticated = true;
      this.user = user;

      const { profile } = user;

      if (profile.stores && profile.stores.length && profile.chains && profile.chains.length) {
        idleTimer(20 * 60 * 1000, () => {
          this.userManager.signoutRedirect();
        });
        this.props.appStore.handleUser(user);
        this.isAuthorized = true;
      } else {
        // sadness
        this.isAuthorized = false;
      }
    }

    IEdetection = () => {
      const ua = window.navigator.userAgent;

      // IE Version < 11
      const msie = ua.indexOf('MSIE ');

      // IE Version 11
      const trident = ua.indexOf('Trident/');

      return msie > 0 || trident > 0;
    };

    render() {
      const { children } = this.props;

      if (this.IEdetection() && !this.hasAuthenticated && !window.location.hash.startsWith('#id_token')) {
        const showIEInfo: Alert = {
          title: 'Information om webbläsare',
          content: (
            <>
              <Typography>Denna webbläsare stöds inte och innehåller kända fel.</Typography>
              <Typography>Vi rekommenderar någon av följande webbläsare:</Typography>
              <Typography>Google Chrome / Microsoft Edge / Mozilla Firefox</Typography>
            </>
          ),
          hideCancel: true,
          acceptCallback: () => {
            this.signinRedirect();
          },
        };

        return (
          <>
            <Grid container justifyContent="center">
              <ListItem>
                <div>
                  <LogoSvg />
                </div>
              </ListItem>
              <Typography color="primary" variant="h2">
                Online Service
              </Typography>
            </Grid>
            <AlertDialog appStore={this.financingStore.appStore} alert={showIEInfo}></AlertDialog>
          </>
        );
      }

      if (!this.hasAuthenticated) {
        return (
          <Container>
            <Grid style={fullscreenCentered} container alignItems="center" justifyContent="center">
              <Grid item>
                <CircularProgress />
              </Grid>
              <Grid item>
                <Typography>Authenticating...</Typography>
              </Grid>
            </Grid>
          </Container>
        );
      }

      if (!this.isAuthorized) {
        return (
          <Container>
            <Grid
              style={fullscreenCentered}
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Typography>
                  The authenticated user ({this.user && this.user.profile.sub}) does not belong to any chain
                  or store.
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.userManager.signoutRedirect();
                  }}
                >
                  Logga ut
                </Button>
              </Grid>
            </Grid>
          </Container>
        );
      }

      return children;
    }
  }
);

const fullscreenCentered = {
  height: '100vh',
};
