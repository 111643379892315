import { Field, Form, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Grid, Input } from '@material-ui/core';
import { ValidationTextField } from 'ui';
import { FinancingStore, IdentificationSubmit } from '../../FinancingStore';
import { IdentificationSchema } from './IdentificationSchema';
import BankIdButton from 'app/components/BankIdButton';
import { MaskedSsnInput } from 'app/components/MaskedSsnInput';

type IProps = { financingStore: FinancingStore } & FormikProps<IdentificationSchema>;

export const IdentificationForm = observer(
  class IdentificationForm extends React.Component<IProps> {
    render() {
      const { financingStore, submitForm, isSubmitting, setFieldValue, errors } = this.props;
      const { startApplicationFailed } = financingStore;

      return (
        <Form>
          <Grid container>
            <Grid item sm={12}>
              <Field
                style={{ paddingBottom: '10px' }}
                label="Personnummer (12-siffror)"
                name="socialSecurityNumber"
                placeholder="ÅÅÅÅMMDD-XXXX"
                variant="filled"
                component={ValidationTextField}
                fullWidth
                autoComplete="off"
                InputProps={{
                  inputComponent: MaskedSsnInput,
                }}
              />
              <Field type="hidden" name="newApplication" />
              <Field
                style={{ paddingBottom: '10px' }}
                label="Sökt belopp"
                name="purchaseAmount"
                component={ValidationTextField}
                placeholder="Sökt belopp"
                fullWidth
                variant="filled"
                type="number"
                InputProps={{
                  inputComponent: Input,
                }}
              />
            </Grid>
          </Grid>

          <Grid container direction="column" alignItems="stretch">
            <Grid item>
              <BankIdButton
                fullWidth
                variant="contained"
                color="primary"
                disabled={true}
                onClick={async () => {
                  setFieldValue('newApplication', true);
                  financingStore.error = { text: '', value: '' };
                  financingStore.signingUrl = '';
                  financingStore.showSigningButton = false;
                  financingStore.setSubmitMethod(IdentificationSubmit.NewApplication);

                  await Promise.resolve();
                  submitForm();
                }}
                isSigning={isSubmitting}
                signingError={!Object.keys(errors).length && startApplicationFailed}
                signingErrorText="Misslyckades att skapa en ansökan"
              >
                Ny ansökan
              </BankIdButton>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item sm={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  disabled={true}
                  onClick={async () => {
                    setFieldValue('newApplication', false);
                    financingStore.error = { text: '', value: '' };
                    financingStore.signingUrl = '';
                    financingStore.showSigningButton = false;
                    financingStore.setSubmitMethod(IdentificationSubmit.PendingApplications);

                    await Promise.resolve();
                    submitForm();
                  }}
                >
                  Återuppta ansökan
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      );
    }
  }
);
