import {
  AppBar,
  Button,
  Container,
  createStyles,
  Drawer,
  Grid,
  Hidden,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemText,
  MenuItem as DropDownMenuItem,
  Theme,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import Settings from '@material-ui/icons/Settings';
import AlertDialog from 'app/AlertDialog';
import { AppStore } from 'app/AppStore';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as LogoSvg } from '../../resources/avida-logo.svg';
import MenuItem from '../MenuItem';
import { PageContext } from '../PageContext';
import { FinancingStore, getFinancingStore } from '../../features/financing/FinancingStore';
import { Alert, AlertContract } from 'app/domain/Alert';
import AlertDialogContract from 'app/AlertDialogContract';
import Banner from './Banner';
import { RouteComponentProps, withRouter } from '../withRouter';

function isAlertContract(alert: Alert): alert is AlertContract {
  return 'isSigningAlert' in alert;
}

const menuItems = [
  { title: 'Finansiering', to: '/financing' },
  { title: 'Återköp', to: '/return' },
  { title: 'Produkter', to: '/products' },
];

const styles = (theme: Theme) =>
  createStyles({
    helpBar: {
      padding: theme.spacing(1),
      background: theme.palette.secondary.main,
      marginBottom: theme.spacing(2),
    },
    logo: {
      display: 'inline-block',
      position: 'relative',
      height: 'auto',
      width: '100%',
      verticalAlign: 'middle',
      overflow: 'hidden',
      minWidth: '300px',
    },

    page: {
      width: '100%',
      maxWidth: '1080px',
      padding: '0 40px',
      boxSizing: 'border-box',
      marginBottom: '100px',
      display: 'block',
    },
    footer: {
      marginTop: 'auto',
      padding: '40px 40px',
    },
    wrapper: {
      minHeight: '99vh',
    },
    header: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
    },
    progress: {},
    name: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    menu: {
      borderTop: '1px solid #efefef',
      borderBottom: '1px solid #efefef',
      marginBottom: theme.spacing(),
    },
    menuItem: {
      marginTop: theme.spacing() / 2,
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing() / 2,
    },
    appBar: {
      background: theme.palette.primary.main,
      padding: 0,
      margin: 0,
      minHeight: '3px',
      maxHeight: '3px',
    },
    drawer: {
      width: '250px',
    },
    descriptionRed: {
      color: '#ec0000',
      marginBottom: theme.spacing(),
    },
    descriptionRedBold: {
      color: '#ec0000',
      fontWeight: 'bold',
      marginBottom: theme.spacing(),
    },
  });

interface IProps extends WithStyles<typeof styles> {
  appStore: AppStore;
}

class PageLayout extends React.Component<IProps & RouteComponentProps> {
  title = '';
  subtitle = '';
  financingStore: FinancingStore = getFinancingStore().withAppStore(this.props.appStore);

  constructor(props: IProps & RouteComponentProps) {
    super(props);

    makeObservable(this, {
      title: observable,
      subtitle: observable,
    });
  }

  public render() {
    const { children, classes } = this.props;
    const { appStore } = this.props;
    const titleActions = {
      setSubtitle: (subtitle: string) => {
        this.subtitle = subtitle;
      },
      setTitle: (title: string) => {
        this.title = title;
      },
    };
    const { currentPath, toggleDrawer, showMenu, goToPath, currentChainId, changeChain } = appStore;
    const isMultiChain = appStore.user.chains.length > 1;
    let selectedChainId: string;

    const showworkFlowAlert: Alert = {
      title: 'Byta kedja',
      redTitle: true,
      acceptText: 'Byta Kedja',
      content: (
        <>
          <Typography className={classes.descriptionRed}>
            Om du byter kedja under en pågående ansökan kommer pågående ansökan avbrytas och du kommer bli
            omdirigerad till första sidan.
            <Typography>
              Bekräfta att du vill byta kedja genom att klicka på BYTA KEDJA knappen, du kan fortsätta ansökan
              genom att klicka på Avbryt.
            </Typography>
          </Typography>
        </>
      ),
      hideCancel: false,
      acceptCallback: () => {
        changeChain(selectedChainId);
        selectedChainId = '';
        this.props.navigate('/financing');
      },
    };

    return (
      <>
        <AppBar className={classes.appBar} position="sticky">
          {appStore.isLoading && <LinearProgress />}
        </AppBar>
        <Container maxWidth="lg">
          <Banner appStore={appStore} />
          <Grid container direction="column" className={classes.wrapper} justifyContent="space-between">
            <Grid item md={12}>
              <Grid container className={classes.header} justifyContent="space-between" alignItems="center">
                <Grid item>
                  <ListItem
                    button
                    onClick={() => {
                      goToPath('/financing');
                    }}
                  >
                    <div className={classes.logo}>
                      <LogoSvg />
                    </div>
                  </ListItem>
                </Grid>
                <Hidden smUp>
                  <Grid item>
                    <MenuIcon onClick={toggleDrawer()} />
                  </Grid>
                </Hidden>
                <Drawer open={showMenu} onClose={toggleDrawer()}>
                  <div
                    className={classes.drawer}
                    role="presentation"
                    onClick={toggleDrawer()}
                    onKeyDown={toggleDrawer()}
                  >
                    <List>
                      {menuItems.map((item) => (
                        <ListItem button key={item.title} onClick={() => goToPath(item.to)}>
                          <ListItemText primary={item.title} />
                        </ListItem>
                      ))}
                      {appStore.currentStore?.isAdmin && (
                        <ListItem button onClick={() => goToPath('/admin')}>
                          <ListItemText primary={'Admin'} />
                        </ListItem>
                      )}
                    </List>
                  </div>
                </Drawer>
                <Hidden xsDown>
                  <Grid item>
                    <Grid container alignItems="center">
                      {isMultiChain && (
                        <Grid item>
                          <TextField
                            select
                            style={{ width: 240 }}
                            label="Kedja"
                            placeholder="Välj i listan"
                            variant="filled"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              if (window.location.pathname.startsWith('/financing/')) {
                                selectedChainId = event.target.value;
                                this.financingStore.appStore.showAlert(showworkFlowAlert);
                              } else {
                                changeChain(event.target.value);
                              }
                            }}
                            value={currentChainId}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            {appStore.user?.chains.map((chain) => (
                              <DropDownMenuItem key={chain.id} value={chain.id}>
                                {chain.name}
                              </DropDownMenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      )}
                      <Grid item>
                        <Link component={RouterLink} to="/settings">
                          <Grid container alignItems="center">
                            <Grid item>
                              <Settings color="primary" />
                            </Grid>
                            <Grid item>
                              <Typography gutterBottom color="primary">
                                {appStore.user?.firstName + ' ' + appStore.user?.lastName}
                              </Typography>
                              <Typography color="primary" variant="caption">
                                {appStore.currentStore?.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Button fullWidth variant="outlined" color="primary" onClick={appStore.logOut}>
                          Logga ut
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
              <Hidden xsDown>
                <Grid container className={classes.menu}>
                  <Grid item className={classes.menuItem}>
                    <MenuItem active={currentPath} to="/financing">
                      Finansiering
                    </MenuItem>
                  </Grid>
                  <Grid item className={classes.menuItem}>
                    <MenuItem active={currentPath} to="/return">
                      Återköp
                    </MenuItem>
                  </Grid>
                  <Grid item className={classes.menuItem}>
                    <MenuItem active={currentPath} to="/future-payments">
                      Uppskjutna betalningar
                    </MenuItem>
                  </Grid>
                  <Grid item className={classes.menuItem}>
                    <MenuItem active={currentPath} to="/products">
                      Produkter
                    </MenuItem>
                  </Grid>

                  {appStore.currentStore?.isAdmin && (
                    <Grid item className={classes.menuItem}>
                      <MenuItem active={currentPath} to="/admin">
                        Admin
                      </MenuItem>
                    </Grid>
                  )}
                </Grid>
              </Hidden>
              <Grid container>
                <Grid item sm={12}>
                  <Grid container>
                    <Grid item sm={12}>
                      <PageContext.Provider value={titleActions}>{children}</PageContext.Provider>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {appStore.alerts.map((a) =>
            isAlertContract(a) ? (
              <AlertDialogContract
                appStore={appStore}
                alert={a}
                key={a.id}
                financingStore={this.financingStore}
              />
            ) : (
              <AlertDialog appStore={appStore} alert={a} key={a.id} />
            )
          )}
        </Container>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(observer(PageLayout)));
